import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import CV from 'react-cv';
import Header from './header';
import reportWebVitals from './reportWebVitals';

const CVData = {
  personalData: {
      nom: 'Dubuc',
      prenom: 'Jean',
      name: "Jean DUBUC",
      title: 'LP Mecatronique',
      image: 'https://media-exp1.licdn.com/dms/image/C4D03AQEdhZa0L2u0fQ/profile-displayphoto-shrink_200_200/0/1650408332138?e=1655942400&v=beta&t=dBS7l0hGw8kkmwhdeinacbpytQ2dHJOY2KCRGuFhtrM',
      contacts: [
          { type: 'email', value: 'jean.dubuc56@gmail.com' },
          { type: 'phone', value: '[+33] 06 65 27 42 78' },
          { type: 'location', value: '1 impasse Victor Hugo, Lorient 56100' },
          { type: 'website', value: 'cv.jeandubuc.fr' },
          { type: 'linkedin', value: 'linkedin.com/in/jeandubuc56' },
          { type: 'github', value: 'github.com/jeand56' }
      ]
  },
  sections: [
        //{
        //  type: 'text',
        //  title: 'Profile',
        //  content: "<inserez un description>",
        //  icon: 'usertie'
        //},
      {
          type: 'common-list',
          title: 'Formations',
          icon: '<iconeFormation>',
          items: [
              {
                  title: 'DUT GEII',
                  authority: 'IUT Rennes',
                  authorityWebSite: 'https://iut-rennes.univ-rennes1.fr',
                  rightSide: '2019 - 2021'
              },
              {
                  title: 'BAC STI2D [option : Énergie et Environement]',
                  authority: 'Lycée Polyvalent Pierre de Coubertin',
                  rightSide: '2019'
              }
          ]
      },
      {
          type: 'experiences-list',
          title: 'Experiences',
          //description: 'Optional',
          icon: '<iconeExperiences>',
          items: [
              {
                  title: 'Monteur-câbleur',
                  company: 'Group Fives',
                  description: `Aide a la création de convoyeur pour préparation de piece pour les chantier et pour le montage de convoyeur sur atelier`,
                  companyWebSite: 'https://www.fivesgroup.com/fr',
                  datesBetween: "11-2021 - Aujourd\'hui",
                  //descriptionTags: ['Javascript', 'React']
              },
              {
                  title: 'Monteur-câbleur',
                  company: 'IUT de Rennes',
                  description: `Câblage de 2 armoires : 1 automate transitique / 1 automate chef d'orchestre / tirage de câble dans différentes machines afin de les relier à l'automate chef d'orchestre`,
                  companyWebSite: 'https://iut-rennes.univ-rennes1.fr',
                  datesBetween: '06-2021 - 07-2021'
              }
          ]
      },
      /*{
          type: 'projects-list',
          title: 'Projects',
          description: 'Optional',
          icon: 'tasks',
          groups: [
              {
                  sectionHeader: 'Company Name',
                  description: 'Optional',
                  items: [
                      { title: 'Project', projectUrl: 'optional', description: 'Optional' },
                      { title: 'Project', projectUrl: 'optional', description: 'Optional' },
                      { title: 'Project', projectUrl: 'optional', description: 'Optional' }
                  ]
              }
          ]
      },*/
      {
          type: 'common-list',
          title: 'Langues',
          icon: 'language',
          items: [
              {
                  authority: 'Français',
                  authorityMeta: 'Langue Maternelle'
              },
              {
                  authority: 'Anglais',
                  //authorityMeta: 'Professional'
              }
          ]
      },
      {
          type: 'tag-list',
          title: 'Compétences',
          icon: 'rocket',
          items: ['Travail D\'Équipe', 'Créativité', 'Curiosité intellectuelles']
      },
      {
          type: 'tag-list',
          title: 'Outils',
          icon: 'rocket',
          items: ['Visual Studio', 'TiaPortal', 'Proteus', 'Git', 'GitHub', 'Suite Office', 'Suite Google']
      },
      {
          type: 'tag-list',
          title: 'Langage de Programation',
          icon: 'rocket',
          items: ['LADDER', 'Grafcet', 'SCL', 'VBA','JS', 'Python', 'C', 'VHDL', 'Arduino']
      },
      {
          type: 'tag-list',
          title: 'Hobbies',
          icon: 'cubes',
          items: ['Jeu Vidéo', 'Film', 'Music', 'Programation']
      }
  ]
};
ReactDOM.render(
  <React.StrictMode>
    <CV personalData={CVData.personalData} sections={CVData.sections}></CV>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
